import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PaginationService {

  constructor() { }

  count: any= 0;
  simpleObservable = new Subject();
  simpleObservable$ = this.simpleObservable.asObservable();
  addCount() {
    this.count+=1;
    this.simpleObservable.next(this.count)
  }
  removeCount() {
    if (this.count > 0) { this.count-=1 };
    this.simpleObservable.next(this.count)
  }
  getCount(){
    return this.simpleObservable$;
  }
}
