import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CommonHeaderComponent } from '../components/common-header/common-header.component';
import { CommonFooterComponent } from '../components/common-footer/common-footer.component';
// Material module start
import {MatExpansionModule} from '@angular/material/expansion';
import {MatIconModule} from '@angular/material/icon';
import {MatMenuModule} from '@angular/material/menu';
import {MatBadgeModule} from '@angular/material/badge';
import {MatButtonModule} from '@angular/material/button';
import {MatNativeDateModule, MatRippleModule} from '@angular/material/core';
import {MatTabsModule} from '@angular/material/tabs';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatSelectModule} from '@angular/material/select';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatRadioModule} from '@angular/material/radio';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatCardModule} from '@angular/material/card';
// Material module end
import { NgOtpInputModule } from 'ng-otp-input';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import {RouterModule} from '@angular/router';
import { FormsModule } from '@angular/forms';
import { OnlyNumberDirective } from '../directives/only-number.directive';
import { NgScrolltopModule } from 'ng-scrolltop';
@NgModule({
  declarations: [
    CommonHeaderComponent, //header component share
    CommonFooterComponent,  //footer component share
    OnlyNumberDirective, //Only Number
  ],
  imports: [
    CommonModule,
    FormsModule,
    // Material module start
    RouterModule,
    MatExpansionModule,
    MatIconModule,
    MatMenuModule,
    MatBadgeModule,
    MatButtonModule,
    MatRippleModule,
    MatTabsModule,
    MatFormFieldModule,
    MatAutocompleteModule,
    MatInputModule,
    MatSelectModule,
    MatTooltipModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatRadioModule,
    MatCheckboxModule,
    MatCardModule,
    // Material module end
    NgOtpInputModule,
    NgxIntlTelInputModule,
    BsDropdownModule.forRoot(),
    NgScrolltopModule,

  ],
  exports: [
    CommonHeaderComponent, //header component share
    CommonFooterComponent,  //footer component share
    FormsModule,
    OnlyNumberDirective, //Only Number
    // Material module start
    MatExpansionModule,
    MatIconModule,
    MatMenuModule,
    MatBadgeModule,
    MatButtonModule,
    MatRippleModule,
    MatTabsModule,
    MatFormFieldModule,
    MatInputModule,
    MatAutocompleteModule,
    MatSelectModule,
    MatTooltipModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatRadioModule,
    MatCheckboxModule,
    MatCardModule,
    // Material module end
    NgxIntlTelInputModule,
    BsDropdownModule,
    NgScrolltopModule,
  ],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ],
})
export class SharedModule { }
