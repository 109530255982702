import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './services/auth/auth.guard';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full'
  },
  {
    path: 'home',
    loadChildren: () => import('./pages/home/home.module').then( m => m.HomePageModule),
    // canLoad: [AuthGuard] 
  },
  {
    path: 'product-list/:id/:type',
    loadChildren: () => import('./pages/product-list/product-list.module').then( m => m.ProductListPageModule),
    //  canLoad: [AuthGuard] 
  },
  {
    path: 'product-view/:id/:type',
    loadChildren: () => import('./pages/product-view/product-view.module').then( m => m.ProductViewPageModule),
    //  canLoad: [AuthGuard] 
  },
  {
    path: 'checkout/:key/:id',
    loadChildren: () => import('./pages/checkout/checkout.module').then( m => m.CheckoutPageModule),
     canLoad: [AuthGuard] 
  },
  {
    path: 'profile',
    loadChildren: () => import('./pages/profile/profile.module').then( m => m.ProfilePageModule),
     canLoad: [AuthGuard] 
  },
  {
    path: 'order-success',
    loadChildren: () => import('./pages/order-success/order-success.module').then( m => m.OrderSuccessPageModule)
  },
  {
    path: 'contact-us',
    loadChildren: () => import('./pages/contact-us/contact-us.module').then( m => m.ContactUsPageModule)
  },
  {
    path: 'about-us',
    loadChildren: () => import('./pages/about-us/about-us.module').then( m => m.AboutUsPageModule)
  },
  {
    path: 'blog',
    loadChildren: () => import('./pages/blog/blog.module').then( m => m.BlogPageModule)
  },
  {
    path: 'privacy-policy',
    loadChildren: () => import('./pages/privacy-policy/privacy-policy.module').then( m => m.PrivacyPolicyPageModule)
  }, 
  {
    path: 'terms-condition',
    loadChildren: () => import('./pages/terms-condition/terms-condition.module').then( m => m.TermsConditionPageModule)
  },


];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
