import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AddtocartService {

  private count: any = 0;
  // simpleObservable = new Subject();
  // simpleObservable$ = this.simpleObservable.asObservable();
  constructor() { }
  setCount(count: any) {
    this.count = count;
    //this.simpleObservable.next(this.count)
  }
  // removeCount() {
  //   if (this.count > 0) { this.count-=1 };
  //   this.simpleObservable.next(this.count)
  // }
  getCount(){
    return this.count;
  }
}
